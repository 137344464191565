import HomePageMobile from './HomePageMobile';
import HomePageDesktop from './HomePageDesktop';
import { basePageWrap } from '../BasePage';


const DesignPage = props => {
    return (
        <>
            <div className="lg:hidden">
                <HomePageMobile {...props} />
            </div>
            <div className="lg:block hidden">
                <HomePageDesktop {...props} />
            </div>
        </>
    )
};


export default basePageWrap(DesignPage)