import React from 'react';

// import i18n from '../../i18n';
// import PropTypes from 'prop-types';


const PageRuler = ({ divisions, theme, style }) => {
    const mainColor = theme === "dark" ? '#161616' : '#EAEAEA';
    const textColor = theme === "dark" ? 'rgb(255 255 255 / 0.25)' : 'rgb(0 0 0 / 0.25)'
    // const borderColor = theme === "dark" ? '#5C5C5C' : '#D5D5D5'
    const zeroPad = (num, places) => String(num).padStart(places, '0')

    return (
        <div className="sm:w-vw-6 w-vw-14 border-r-px sm:py-[8vw] py-vw-56" style={{ background: mainColor, borderColor: textColor, ...style }}>
            {Array(divisions).fill('').map((_, idx) => (
                <div key={idx} className={"flex flex-col justify-end items-center sm:w-vw-6 w-vw-14 sm:h-vw-6 h-vw-14 text-center -rotate-90" + (idx !== divisions-1 ? ' sm:mb-[8vw] mb-vw-52' : '')}>
                    <p className="sm:text-[0.7vw] text-[1.9vw] font-light tracking-[0.05vw]" style={{ color: textColor }}>
                        {zeroPad(idx*250, 4)}
                    </p>
                    <div className="w-px sm:h-vw-1 h-vw-3 mt-vw-0.5" style={{ background: textColor }} />
                </div>
            ))}
        </div>
    )
};

PageRuler.propTypes = {};

PageRuler.defaultProps = {};


export default PageRuler;
