import { useEffect, useState } from 'react';
import Image from 'next/image';
import Link from 'next/link';

import { motion, MotionConfig } from 'framer-motion'

// import i18n from '../../i18n';
import PropTypes from 'prop-types';

import yaroslav from '../../public/img/home/yaroslav.jpg'
import vladislav from '../../public/img/home/vladislav.jpg'
import PageRuler from '../../components/PageRuler';


const HomePageMobile = ({ title }) => {
    const [height, setHeight] = useState('100vh');
    const [halfActive, setHalfActive] = useState(null);
    const [animates, setAnimates] = useState({
        0: 'initial',
        1: 'initial'
    });

    const variants = {
        initial: {
            height: '50%',
            background: '#C3C3C3',
            pointerEvents: ''
        },
        inactive: {
            height: '0%',
            pointerEvents: 'none'
        },
        active: {
            height: '100%',
            pointerEvents: 'none'
        }
    }

    useEffect(() => {
        if (halfActive === 0) {
            return setAnimates({
                0: 'active',
                1: 'inactive'
            })
        } else if (halfActive === 1) {
            return setAnimates({
                0: 'inactive',
                1: 'active'
            })
        } else {
            return setAnimates({
                0: 'initial',
                1: 'initial'
            })
        }
    }, [halfActive])

    useEffect(() => {
        const getSetHeight = () => setHeight(window.innerHeight);

        if (typeof window !== 'undefined') {
            getSetHeight()
        }
    }, [])

    return (
        <div className="flex flex-col justify-between items-center w-screen text-white" style={{ height }}>
            <MotionConfig transition={{ type: 'spring' }}>
                <Link href="/estate">
                    <motion.div
                        className="w-full relative cursor-pointer overflow-hidden"
                        variants={variants}
                        initial="initial"
                        animate={animates[0]}
                        onClick={() => setHalfActive(0)}
                    >
                        <div className="w-full h-full relative scale-125 origin-bottom">
                            <Image src={yaroslav} layout="fill" sizes="100vw" objectFit="cover" objectPosition="center" priority />
                        </div>

                        <div className="flex justify-center items-end w-full h-full absolute inset-0 sm:pb-vw-24 pb-vw-40">
                            <h2 className="main-title text-[7vw] whitespace-nowrap">
                                Подбор / продажа<br/><span className="text-curved">недвижимости</span>
                            </h2>
                        </div>

                        <div className="absolute inset-x-0 bottom-0">
                            <div className="sm:h-vw-6 h-vw-14">
                                <PageRuler divisions={20} theme="dark" style={{ transform: 'rotate(-90deg) scale(-1, 1)', transformOrigin: 'top left' }} />
                            </div>
                        </div>
                    </motion.div>
                </Link>

                <Link href="/design">
                    <motion.div
                        className="w-full relative cursor-pointer overflow-hidden"
                        variants={variants}
                        initial="initial"
                        animate={animates[1]}
                        onClick={() => setHalfActive(1)}
                    >
                        <div className="w-full h-full relative scale-125 origin-bottom">
                            <Image src={vladislav} layout="fill" sizes="100vw" objectFit="cover" objectPosition="center" priority />
                        </div>

                        <div className="flex justify-center items-end w-full h-full absolute inset-0 sm:pb-vw-24 pb-vw-40">
                            <h2 className="main-title text-[7vw] whitespace-nowrap">
                                Дизайнерские<br/><span className="text-curved">ремонты</span>
                            </h2>
                        </div>

                        <div className="absolute inset-x-0 bottom-0">
                            <div className="sm:h-vw-6 h-vw-14">
                                <PageRuler divisions={20} theme="light" style={{ transform: 'rotate(-90deg) scale(-1, 1)', transformOrigin: 'top left' }} />
                            </div>
                        </div>
                    </motion.div>
                </Link>
            </MotionConfig>
        </div>
    );
};

HomePageMobile.defaultProps = {
    title: '',
};

HomePageMobile.propTypes = {
    title: PropTypes.string.isRequired,
};


export default HomePageMobile;
